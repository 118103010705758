import { onUnmounted, onMounted } from "vue"
import { setMultiEnviornmentCookie, removeCookie, getCookieValue, OS } from "./views/common"
import { useCurrentElement } from '@vueuse/core'
import metrics from "./metrics"

let config = {
    "nav": {
        "nav-dashboard":   { name: "Open Dashboard Page",   default: "ctrl+alt+u" },
        "nav-bookmarks":   { name: "Open Bookmarks Page",   default: "ctrl+alt+d" },
        "nav-build":       { name: "Open Build Page",       default: "ctrl+alt+b" },
        "nav-history":     { name: "Open History Page",     default: "ctrl+alt+h" },
        "nav-swagger":     { name: "Open Swagger Page",     default: "ctrl+alt+s" },
        "nav-vault":       { name: "Open Vault Page",       default: "ctrl+alt+v" },
        "nav-migrate":     { name: "Open Swagger Page",     default: "ctrl+alt+m" },
        "nav-log":         { name: "Open Log Page",         default: "ctrl+alt+l" },
        "nav-openvpn":     { name: "Open OpenVPN Page",     default: "ctrl+alt+o" },
        "nav-preferences": { name: "Open Preferences Page", default: "ctrl+alt+p" },
    },
    "dashboard": {
        "dashboard-download-report": { name: "Download Report",          default: "ctrl+shift+s", mac: "shift+meta+s" },
        "dashboard-validate":        { name: "Validate Adapter Configs", default: "ctrl+shift+v", mac: "shift+meta+v" },
    },
    "build": {
        "build-find":            { name: "Find",                 default: "ctrl+f",           mac: "meta+f"           },
        "build-save":            { name: "Save",                 default: "ctrl+s",           mac: "meta+s"           },
        "build-deep-find":       { name: "Deep Find",            default: "ctrl+shift+f",     mac: "shift+meta+f"     },
        "build-add-row":         { name: "Add Row",              default: "ctrl+i",           mac: "meta+i"           },
        "build-duplicate-row":   { name: "Duplicate Row",        default: "alt+d",            mac: "alt+d"            },
        "build-deepclone-row":   { name: "Deep Clone Row",       default: "alt+shift+d",      mac: "alt+shift+d"      },
        "build-delete-row":      { name: "Delete Row",           default: "delete",           mac: "delete"           },
        "build-cancel-changes":  { name: "Cancel Changes",       default: "ctrl+shift+x",     mac: "shift+meta+x"     },
        "build-hide-column":     { name: "Hide Column",          default: "ctrl+h",           mac: "meta+h"           },
        "build-unhide-columns":  { name: "Show All Columns",     default: "ctrl+shift+h",     mac: "shift+meta+h"     },
        "build-open-filters":    { name: "Show Filters",         default: "ctrl+g",           mac: "meta+g"           },
        "build-open-sqlfilters": { name: "Show Filters",         default: "ctrl+shift+g",     mac: "shift+meta+g"     },
        "build-open-bookmark":   { name: "Add Bookmark",         default: "ctrl+b",           mac: "meta+b"           },
        "build-excel-export":    { name: "Export",               default: "ctrl+shift+s",     mac: "shift+meta+s"     },
        "build-excel-import":    { name: "Import",               default: "ctrl+shift+o",     mac: "shift+meta+o"     },
        "build-tab-hide":        { name: "Close Code Panel Tab", default: "ctrl+alt+shift+h", mac: "alt+shift+meta+h" },
    },
    "bookmarks": {
        "bookmarks-add-folder":   { name: "Add Bookmark Folder",  default: "ctrl+shift+i", mac: "shift+meta+i" },
        "bookmarks-add-bookmark": { name: "Add Bookmark",         default: "ctrl+i",       mac: "meta+i"       },
    },
    "history": {
        "history-copy-payload": { name: "Copy Input Payload", default: "ctrl+shift+c", mac: "shift+meta+c" },
    },
    "frontends": {
        "frontends-create": { name: "Create Frontend", default: "ctrl+i", mac: "meta+i" },
    },
    "vault": {
        "vault-add-vault": { name: "Add Vault",       default: "ctrl+shift+i", mac: "shift+meta+i" },
        "vault-add-item":  { name: "Add Vault Item",  default: "ctrl+i",       mac: "meta+i"       },
        "vault-save-item": { name: "Save Vault Item", default: "ctrl+s",       mac: "meta+s"       },
    },
    "log": {
        "log-download": { name: "Download Log File", default: "ctrl+shift+s", mac: "shift+meta+s" },
    },
}

const MODIFIER_ORDER = ["ctrl", "alt", "shift", "meta"]

export const modifierSort = (a, b) => {
    return MODIFIER_ORDER.indexOf(a) - MODIFIER_ORDER.indexOf(b)
}

export const keyComboString = (ctrl, alt, shift, meta, key) => {
    const combo = [];

    if (ctrl) combo.push("ctrl")
    if (alt) combo.push("alt")
    if (shift) combo.push("shift")
    if (meta) combo.push("meta")

    combo.push(key.toLowerCase())

    return combo.join("+")
}

function eventKeyComboString(event) {
    // Obtains the physical key pressed, ignoring any and all key modifiers (e.g., Shift, Alt, etc.).
    // This is crucial for macOS support as the value of `event.key` changes if the `Option` key is held down.
    const key = event.code.replace("Key", "").toLowerCase()

    return keyComboString(event.ctrlKey, event.altKey, event.shiftKey, event.metaKey, key)
}

function isActiveElementTextbox() {
    let el = document.activeElement
    let isTextArea = el.tagName == "TEXTAREA"
    let isClipBuffer = el.classList.contains("webix_clipbuffer")
    let isTextInput = el.tagName == "INPUT" && el.attributes.getNamedItem("type")?.value == "text"
    return (isTextArea && !isClipBuffer) || isTextInput
}

// function getZIndex(el) {
//     try {
//         const z = Number(window.getComputedStyle(el).zIndex)
//         return isNaN(z) ? getZIndex(el) : z
//     } catch {
//         return 0
//     }
// }

// function getOverlayZIndex() {
//     let overlays = [...document.getElementsByClassName("v-overlay--active")]
//     if (overlays.length === 0)
//         return 0
//     return Math.max(...overlays.map((overlay) => getZIndex(overlay))) || 1
// }

let nameToKeys = {}
function regenNameToKeys() {
    nameToKeys = {}

    Object.entries(config).forEach(([, bindings]) => {
        Object.entries(bindings).forEach(([name, data]) => {
            nameToKeys[name] = data.combo ?? data[OS] ?? data.default
        })
    })
}

regenNameToKeys()

export const newContext = () => ({ bindings: {}, enabled: true })

// These functions are for the configuration page
export const getKeybindSections = () => Object.keys(config)
export const getKeybindsInSection = (section) => Object.keys(config[section])
export const getKeybindName = (section, keybind) => config[section][keybind].name

export const getNewKeybindCombo = (section, keybind) => {
    const settings = config[section][keybind]
    return settings.newCombo ?? settings.combo ?? settings[OS] ?? settings.default
}

export const getOldKeybindCombo = (section, keybind) => {
    const settings = config[section][keybind]
    return settings.combo ?? settings[OS] ?? settings.default
}

export const getDefaultKeybindCombo = (section, keybind) => {
    const settings = config[section][keybind]
    return settings[OS] ?? settings.default
}

export const setNewKeybindCombo = (section, keybind, newCombo) => {
    config[section][keybind].newCombo = newCombo
}

export const saveConfig = () => {
    const saveData = {}

    getKeybindSections().forEach((section) => {
        saveData[section] = {}

        getKeybindsInSection(section).forEach((keybind) => {
            const newKeybind = getNewKeybindCombo(section, keybind)

            if (newKeybind !== getDefaultKeybindCombo(section, keybind)) {
                saveData[section][keybind] = newKeybind
            }
        })
    })

    setMultiEnviornmentCookie("keybinds", JSON.stringify(saveData))

    loadConfig()
}

export const loadConfig = () => {
    const saveData = JSON.parse(getCookieValue("keybinds") ?? "{}")

    getKeybindSections().forEach((section) => {
        getKeybindsInSection(section).forEach((keybind) => {
            // Ensure backwards compatability with previously-saved localized key combos
            config[section][keybind].combo = generalizeKeyCombo(saveData[section]?.[keybind])
            config[section][keybind].newCombo = null
        })
    })

    regenNameToKeys()
}

export const hasPendingChanges = () => {
    return getKeybindSections().some((section) => {
        return getKeybindsInSection(section).some((keybind) => {
            return getNewKeybindCombo(section, keybind) !== getOldKeybindCombo(section, keybind)
        })
    })
}

export const restoreDefaults = () => {
    removeCookie("keybinds")
    loadConfig()
}

const illegalCombos = {
    // Windows
    win: new Set([
        "ctrl+c",
        "ctrl+v",
        "ctrl+a",
        "ctrl+t",
        "ctrl+w",
        "ctrl+p",
        "ctrl+r",
        "ctrl+d",
        "ctrl+n",
        "ctrl+shift+d",
        "ctrl+shift+n",
    ]),

    // macOS
    mac: new Set([
        "meta+c",
        "meta+v",
        "meta+a",
        "meta+t",
        "meta+w",
        "meta+p",
        "meta+r",
        "meta+d",
        "meta+n",
        "meta+m",
        "meta+shift+d",
        "meta+q",
        "meta+shift+q",
        "meta+alt+d",
        "meta+alt+m",
        "meta+shift+j",
        "meta+shift+t",
    ]),
}

export const getBindingConflicts = () => {
    const conflicts = {}
    const sections = Object.keys(config)

    const testConflictingCombos = (sections) => {
        const counts = {}

        sections.forEach((section) => {
            Object.values(config[section]).forEach((settings) => {
                const combo = settings.newCombo ?? settings.combo ?? settings[OS] ?? settings.default

                counts[combo] = (counts[combo] ?? 0) + 1

                // Use Windows as a fallback
                if ((illegalCombos[OS] ?? illegalCombos.win).has(combo))
                    conflicts[combo] = true
                else if (counts[combo] > 1)
                    conflicts[combo] = true
            })
        })
    }

    sections.forEach((section) => testConflictingCombos([section]))
    sections.filter((section) => section !== "nav").forEach((section) => testConflictingCombos([section, "nav"]))

    return conflicts
}

export const localizeKeyCombo = (combo) => {
    switch (OS) {
        case "windows": {
            return combo
                ?.replace("meta", "win")
        }
        case "mac": {
            return combo
                ?.replace("meta", "cmd")
                ?.replace("alt", "opt")
        }
        case "linux": {
            return combo
                ?.replace("meta", "super")
        }
    }

    return combo
}

export const generalizeKeyCombo = (combo) => {
    if (!combo) return combo

    const generalCombo = combo
        .replace(/win|cmd|super/, "meta")
        .replace("opt", "alt")

    const keys = generalCombo.split("+")

    if (keys.length < 2)
        return generalCombo

    // To preserve backwards compatability with saved key combos that had
    // their modifiers in a different order, we order them properly
    const modifiers = keys.slice(0, -1).sort(modifierSort)
    const key = keys[keys.length - 1]

    return [...modifiers, key].join("+")
}

loadConfig()

// keybind app
let appbinds = {}
let nextid = 1

export const handleAppEvent = (event) => {
    const combo = eventKeyComboString(event)

    Object.entries(appbinds).forEach(([name, funcList]) => {
        if (nameToKeys[name] === combo) {
            let disabled = isActiveElementTextbox()

            if (!disabled && funcList.length > 0) {
                const { callback, el, options } = funcList[funcList.length - 1]
                // TODO: Add back zindex checks -> getZIndex(el.value) >= getOverlayZIndex()
                if (!el || document.contains(el.value)) {
                    if (options.metric) {
                        metrics.hotkey(options.metric, name)
                    }

                    callback(event)
                }
                event.stopPropagation()
                event.preventDefault()
            }
        }
    })
}

document.addEventListener("keydown", (e) => handleAppEvent(e))

function removeKeybind(name, id) {
    appbinds[name] = appbinds[name].filter(binding => binding.id != id)
}

export const useKeybind = (name, callback, options = {}) => {
    const id = nextid++

    onMounted(() => {
        const el = useCurrentElement()

        appbinds[name] = appbinds[name] ?? []
        appbinds[name].push({ callback, id, el, options })
    })

    onUnmounted(() => {
        removeKeybind(name, id)
    })
}

export const keybindPlugin = {
    install: (app) => {

        /// this add no longer provides cleanup
        app.config.globalProperties.$keybindAdd = function(name, callback, options = {}) {
            let id = nextid++
            appbinds[name] = appbinds[name] || []
            appbinds[name].push({ callback, id, options })

            return () => removeKeybind(name, id)
        }

        app.config.globalProperties.$keybindGetKeyCombo = function(name) {
            const keyCombo = nameToKeys[name]

            if (!keyCombo) {
                if (process.env.NODE_ENV !== "production") {
                    console.warn(`The keybind '${name}' doesn't exist.`)
                }

                return null
            }

            return localizeKeyCombo(keyCombo)
        }
    }
}
